import React from "react";
import "swiper/css";
import ApcomPng from "~/images/companyLogo/apcom.png";
import CyberagentPng from "~/images/companyLogo/cyberagent.png";
import DenaPng from "~/images/companyLogo/dena.png";
import EbaraPng from "~/images/companyLogo/ebara.png";
import KeizaisanngyosyoPng from "~/images/companyLogo/nihonkeisannsyou.png";
import LitalicoPng from "~/images/companyLogo/litalico.png";
import MarubinePng from "~/images/companyLogo/marubeni.png";
import NihonsoukenPng from "~/images/companyLogo/nihonsouken.png";
import SansanPng from "~/images/companyLogo/sansan.png";
import TechnoprodesignPng from "~/images/companyLogo/technoprodesign.png";
import ToyoengineeringPng from "~/images/companyLogo/toyoengineering.png";
import YayoiPng from "~/images/companyLogo/yayoi.png";

export const CompanyLogos: React.FC = () => (
  <>
    <div className="companyLogs">
      <div className="scroll-container">
        {/* NOTE: ロゴが途中で途切れないようにScrollContentは3つ繋げる */}
        <ScrollContent />
        <ScrollContent />
        <ScrollContent />
      </div>
    </div>

    <style jsx>{`
      .companyLogs {
        margin-top: 24px;
        margin-bottom: 24px;
      }
      .scroll-container {
        display: flex;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
    `}</style>
  </>
);

// NOTE: ロゴは高さ40px、中央寄せのものを作成して利用する
const ScrollContent = () => (
  <>
    <div className="scroll-content">
      <div className="item">
        <img src={ApcomPng} alt="" />
      </div>
      <div className="item">
        <img src={CyberagentPng} alt="" />
      </div>
      <div className="item">
        <img src={DenaPng} alt="" />
      </div>
      <div className="item">
        <img src={EbaraPng} alt="" />
      </div>
      <div className="item">
        <img src={KeizaisanngyosyoPng} alt="" />
      </div>
      <div className="item">
        <img src={LitalicoPng} alt="" />
      </div>
      <div className="item">
        <img src={MarubinePng} alt="" />
      </div>
      <div className="item">
        <img src={NihonsoukenPng} alt="" />
      </div>
      <div className="item">
        <img src={SansanPng} alt="" />
      </div>
      <div className="item">
        <img src={TechnoprodesignPng} alt="" />
      </div>
      <div className="item">
        <img src={ToyoengineeringPng} alt="" />
      </div>
      <div className="item">
        <img src={YayoiPng} alt="" />
      </div>
    </div>

    <style jsx>{`
      .scroll-content {
        animation: scroll 24s linear infinite;
        display: flex;
        height: 60px;
      }
      .item {
        align-items: center;
        display: flex;
        margin: 0 20px;
      }
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    `}</style>
  </>
);
